.flex{
    display: flex;
}
.generated_image_wraper{
    height: 470px;
    width: 270px;
    border: 1px solid #F0F0F0;
    position: relative;
    margin-right: 10px;
}
.generated_image_wraper img{
    position: absolute;
    left: 0;
    top: 0;
}
.face_items{
    width: 60px;
    display: flex;
    flex-direction: column;
    margin-right: 5px;
}
.tools_wraper{
    display: flex;
}
.tools_wraper .face_items div{
    cursor: pointer;
}
.face_item_type{
    width: 60px;
    display: flex;
    flex-direction: column;
}
.face_item_type div{
    cursor: pointer;
}